import React from "react"
import Layout from "../../components/Layout"
import Banner from "../../components/Banner"
import { StaticImage } from "gatsby-plugin-image"
import {
  Section,
  SectionText,
  TopHeader,
  TwoColumn,
} from "../../components/Section"
import { Link } from "gatsby"
import Seo from "../../components/Seo"

export default function AsbestosRemval() {
  return (
    <Layout>
      <Seo
        title="Asbestos Removal"
        description="We provide asbestos testing and asbestos removal in all Lower Mainland."
      />
      <Section>
        <TopHeader>
          <h1>Asbestos Removal</h1>
        </TopHeader>
        <TwoColumn>
          <SectionText>
            <p>
              Asbestos removal services are often demanded by people for old
              constructed buildings. In the early 20’s to the 90’s Asbestos was
              widely used in construction materials like reinforcing components
              or as material for thermal, electrical, or acoustic insulation. It
              is capable of silently causing mesothelioma (cancer that affects
              the lining of the lungs and the lining surrounding the lower
              digestive tract), lung cancer, asbestosis, and pleural thickening.
              These four diseases often take more than a decade to develop.
            </p>
            <p>
              Asbestos removal is not a simple matter. It not only takes a lot
              of strength and time, but it also requires an extensive amount of
              knowledge, experience, testing, technique, and expertise, as it is
              hard to detect because asbestos is often mixed with other
              materials.{" "}
              <em>
                Asbestos removal is a job that should be done by an expert and
                certified team.
              </em>
            </p>
          </SectionText>
          <StaticImage
            src="../../images/asbestos.png"
            alt="Two workers in HAZMAT suits"
            placeholder="blurred"
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
        </TwoColumn>
      </Section>
      <Section>
        <Banner>
          <h2>Free asbestos sampling and testing for renovations</h2>
          <p>
            We offer FREE asbestos sampling and testing for renovations that get
            sent to our lab partner, so you can begin your project with peace of
            mind.
          </p>
          <Link to="../../contact-us">Contact us</Link>
        </Banner>
      </Section>
      <Section>
        <TwoColumn>
          <SectionText>
            <p>
              We ensure that all asbestos waste and any other contaminated waste
              are arranged in sealed containers and labeled properly. According
              to{" "}
              <Link to="https://www.worksafebc.com/en/resources/health-safety/books-guides/safe-work-practices-for-handling-asbestos?_ga=1.88842844.612901632.1455308346">
                Safe Work Practices for Handling Asbestos
              </Link>
              , asbestos must be double-bagged in 6 mil poly bags, sealed with
              duct tape and label. A prior settlement should be done with the
              authorities to transport any hazardous waste to assigned
              landfills.
            </p>
          </SectionText>
          <SectionText>
            <p>
              Our experience enables us to do a hassle-free job, always
              considering the environment and trying to recover all materials
              that can be recycled and reused. If you want additional
              information about asbestos and how to properly handle it, please
              visit <Link to="https://www.worksafebc.com/en">WorkSafeBC</Link>.
              If you need a <em>professional, effective, and skilled</em>{" "}
              asbestos removal in Vancouver, we can help you with your
              demolition or renovation project. Do not hesitate to contact us!
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
    </Layout>
  )
}
