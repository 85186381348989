import React from "react"
import styled from "styled-components"

const BannerWrapper = styled.div`
  background-color: ${props => (props.darkGreen ? "#213134" : "#ddf7f2")};
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 15px;

  h2, h3 {
    color: ${props => (props.darkGreen ? "white" : "#005458")};
    font-weight: 700;
    font-size: 2rem;
    max-width: 40rem;
    margin: 0;
    align-self: center;
  }

  p {
    color: ${props => (props.darkGreen ? "#BEE4EC" : "#007A80")};
    font-weight: 600;
    font-size: 1.3rem;
    max-width: 40rem;
    align-self: center;
  }

  a {
    background-color: ${props => (props.darkGreen ? "white" : "#005458")};
    color: ${props => (props.darkGreen ? "#005458" : "white")};
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    padding: 1rem 2.5rem;
    margin-top: 1rem;
    border-radius: 10px;
    align-self: center;
    transition: 0.1s ease-out;

    &:hover {
      -webkit-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
      box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
      transform: scale(1.05);
      transition: 0.1s ease-in;
    }
    
  }

  .banner-btn-text {
    display: flex;
    justify-content: space-between;

    img {
      margin-left: 0.8rem;
    }
  }
`

export default function Banner(props) {
  return <BannerWrapper {...props}>{props.children}</BannerWrapper>
}
