import styled from "styled-components"

export const Section = styled.div`
  max-width: 80rem;
  margin: 6rem auto;
  padding: 0 1rem;

  @media screen and (max-width: 700px) {
    margin: 3rem auto;
  }
`

export const SectionText = styled.div`
  text-align: ${props => (props.center ? "center" : "")};

  h1 {
    text-align: left;
  }

  h3 {
    margin-top: -0.6rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .sub {
    margin-top: 0;
    margin-block-end: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 800;
    color: #017d79;
  }

  p {
    margin: ${props => (props.center ? "auto" : "")};
    margin-bottom: ${props => (props.spaceBottom ? "5rem" : "1rem")};
    margin-top: 1rem;
    max-width: ${props => (props.smallWidth ? "35rem" : "")};
    font-size: 1.3rem;
    margin-block-start: -0.3rem;
  }
`

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
  column-gap: 4rem;
  row-gap: 2rem;
  margin: 4rem 0;

  @media screen and (max-width: 700px) {
    margin: 2rem auto;
  }
`

export const TopHeader = styled.section`
  margin-top: 2rem;    

  @media screen and (max-width: 700px) {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 4rem;
    font-weight: 800;
    line-height: 4rem;
    color: #137e77;
    margin-bottom: 0; 
  
    @media screen and (max-width: 700px) {
      font-size: 2.7rem;
      line-height: 3rem;
    }
  }

  h2 {
    color: #3d3d3d;
    font-size: 1.6rem;
    font-weight: 600;    
    margin-top: 1rem;
    max-width: 50rem;            

    @media screen and (max-width: 700px) {
      font-size: 1.2rem;
    }
  }
`
